export async function fetchPostWebmentionCount() {
	const postDomain = new URL(window.location.href).origin.includes('localhost')
		? 'https://blog.krvpb024.dev'
		: new URL(window.location.href).origin;
	const postId = new URL(window.location.href).pathname;

	const fetchUrl = `https://webmention.io/api/count?target=${postDomain}${postId}`;
	const fetchResponse = await fetch(fetchUrl);
	const fetchData = await fetchResponse.json();

	const { type: { like, reply, repost } } = fetchData;

	const likeCountElement = document.querySelector('.like-count');
	const replyCountElement = document.querySelector('.reply-count');
	const repostCountElement = document.querySelector('.repost-count');

	if (likeCountElement) likeCountElement.innerHTML = like ?? 0;
	if (replyCountElement) replyCountElement.innerHTML = reply ?? 0;
	if (repostCountElement) repostCountElement.innerHTML = repost ?? 0;
}
