export function backToTop() {
	const backToTopElement = document.querySelector('.back-to-top-link');

	document.body.addEventListener('keyup', (event) => {
		const keyName = event.key;

		if (keyName === 'Control') return;

		if (event.ctrlKey && keyName === '/') {
			window.scrollTo({ top: 0 });
			focusOnBody();
		}
	});

	backToTopElement.addEventListener('click', (event) => {
		event.preventDefault();

		window.scrollTo({ top: 0 });
		focusOnBody();
	});
}

function focusOnBody() {
	document.body.setAttribute('tabindex', '-1');
	document.body.focus();
	document.body.removeAttribute('tabindex');
}
